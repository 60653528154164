module.exports = {
  COLORS: {
    stepForwardNormal: '#52c41a',
    stepForwardEnhanced: '#398912',
    stepBackwardNormal: '#ff7b02',
    stepBackwardEnhanced: '#b35601',
    primary: '#a12596',
    primaryDark: '#811e78',
    secondary: '#52c41a',
    secondaryHover: '#409913',
    secondaryLight: '#FFCB52',
    link: '#7D00A3',
    highlight: '#FFA32A',
    faded: '#767676',
    black: '#000000',
    white: '#ffffff',
    // TODO: We should rethink this name
    backgroundAlternative: '#F27979',
    backgroundLightGray: '#0000000d',
    backgroundLightOrange: '#FDF1E6',
    success: '#52C41A',
    brandOrange: '#ff7b02',
    lightBlue: '#1890ff',
    strokeCards: '#F0F0F0',
    greyBackground: '#E5E5E5',
    rgb: {
      success: '82,196,26',
      primary: '24, 144, 255',
      warning: '242, 121, 121',
      brandOrange: '255,123,2',
    },
  },
};
